import * as React from "react"
import { graphql } from "gatsby"

import { TypeInsightArticle, TypeCategory } from "../app/createPages"

import Layout from "../components/layout/layout"
import Seo from "../components/seo/seo"
import Divider from "../components/divider/divider"
import ArticleGrid from "../components/articles/articleGrid"

type TypeData = {
  allSanityCategory: {
    edges: {
      node: TypeCategory
    }[]
  }
  allSanityInsight: {
    edges: {
      node: TypeInsightArticle
    }[]
  }
}

type InsightCategoryTemplateProps = {
  data: TypeData
  pageContext: {
    currentSlug: string
  }
}

const InsightsCategoryTemplate = ({
  data,
  pageContext: { currentSlug },
}: InsightCategoryTemplateProps) => {
  const categories = data.allSanityCategory.edges.map(edge => edge.node)
  const category = categories.find(
    category => category.slug.current === currentSlug
  )
  const articles = data.allSanityInsight.edges.map(edge => edge.node)

  return (
    <Layout>
      <Seo title={`Insights | ${category?.title}`} />
      <section className="container mt-5">
        <div className="mw-800">
          <h4>{category?.title}</h4>
          <p className="body1">{category?.description}</p>
        </div>
        <Divider />
        <ArticleGrid articles={articles} asInsight={true} />
      </section>
    </Layout>
  )
}

export const blogQuery = graphql`
  query InsightsCategoryQuery($currentSlug: String) {
    allSanityCategory {
      edges {
        node {
          title
          slug {
            current
          }
        }
      }
    }
    allSanityInsight(
      sort: { fields: publishedAt, order: DESC }
      filter: { slug: { current: { eq: $currentSlug } } }
    ) {
      edges {
        node {
          title
          excerpt
          id
          mainImage {
            asset {
              gatsbyImageData
            }
          }
          slug {
            current
          }
          publishedAt
          author {
            name
          }
          category {
            title
            slug {
              current
            }
          }
          tags {
            title
            slug {
              current
            }
          }
        }
      }
    }
  }
`

export default InsightsCategoryTemplate
